import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './SevenComponents.module.css';

import Container from '../../../../blocks/Container';
import Title from '../../../../elements/Title';
import Image from '../../../../elements/Image';
import Text from '../../../../elements/Text';

const SevenComponents = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/seven-components.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              texts
              components
            }
          }
        }
      }
    }
  `);

  const { title, texts, components } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.slideContainer}>
      <Container type="big">
        <Container type="mobile">
          <div className="row">
            <div className="col-12">
              <Title className={styles.title}>{title}</Title>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12 d-flex flex-column align-items-xl-end align-items-lg-end align-items-center pr-5">
              {
                texts.map((text, index) => <Text key={index} className={styles.text}>{text}</Text>)
              }
            </div>
            <div className="col-xl-6 col-lg-6 col-12 d-flex flex-column align-items-xl-start align-items-lg-start align-items-center p-xl-0 p-lg-0">
              <div className={styles.componentsContainer}>
                {
                  components.map((component, index) =>
                    <div key={index} className={styles.component}>
                      <div className={styles.component__digit}>{index + 1}</div>
                      <div className={styles.component__text} dangerouslySetInnerHTML={{ __html: component }} />
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </Container>
      </Container>
      <Image name="campaigns/plant" className={styles.image} />
    </div>
  )
}

export default SevenComponents;