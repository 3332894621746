import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import styles from './BulletedList.module.css';

import Text from '../Text';

const BulletedList = ({ color, list, type }) => (
  <Fragment>
    {
      list.map((item, index) => (
        <Text key={index} className={`${styles.bulletedList__detail} ${type === 'big' ? styles.bulletedList__detailBig : ''} w-100`}>
          <div
            className={`${styles.bulletedList__detailMark} ${type === 'big' ? styles.bulletedList__detailMarkBig : ''}`}
            style={{ backgroundColor: color }}
          />
          {item}
        </Text>
      ))
    }
  </Fragment>
);

BulletedList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  color: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['big', 'normal'])
}

BulletedList.dedaultProps = {
  type: 'normal'
}

export default BulletedList;