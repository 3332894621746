import React from 'react';
import Slider from 'react-slick';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './CaseStudies.module.css';

import Container from '../../../../blocks/Container';
import Title from '../../../../elements/Title';
import Image from '../../../../elements/Image';

const CaseStudies = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/case-studies.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              cases {
                name
                text
                image
              }
            }
          }
        }
      }
    }
  `);

  const { title, cases } = data.allMarkdownRemark.edges[0].node.frontmatter;

  const sliderSettings = {
    className: 'brand-slider',
    centerMode: true,
    infinite: true,
    swipeToSlide: true,
    centerPadding: '0',
    slidesToShow: typeof window !== 'undefined' && window.innerWidth <= 768 ? 1 : 2,
    speed: 500,
    dots: false,
    initialSlide: 1,
    autoplay: true
  };

  const renderCaseContainer = caseItem => (
    <div className={styles.caseContainer}>
      <div className="d-flex justify-content-center">
        <Image name={caseItem.image} className={styles.caseContainer__image} />
      </div>
      <div className={styles.caseContainer__title}>{caseItem.name}</div>
      <div className={styles.caseContainer__text}>{caseItem.text}</div>
    </div>
  );

  return (
    <div className={styles.caseStudies}>
      <Container type="big">
        <Container type="mobile">
          <div className="row">
            <div className="col-12">
              <Title className={styles.title}>{title}</Title>
            </div>
          </div>
          <div className="row d-xl-flex d-lg-flex d-none">
            {
              cases.map((caseItem, index) =>
                <div key={index} className="col-xl-4 col-lg-4 col-12 d-flex justify-content-center">
                  {renderCaseContainer(caseItem)}
                </div>
              )
            }
          </div>
          <div className="d-xl-none d-lg-none d-block">
            <Slider {...sliderSettings}>
              {
                cases.map((caseItem, index) => (
                  <div key={index} className="col-12 d-flex justify-content-center align-items-start h-100">
                    {renderCaseContainer(caseItem)}
                  </div>
                ))
              }
            </Slider>
          </div>
        </Container>
      </Container>
    </div>
  )

}

export default CaseStudies;