import React from 'react';
import Slider from 'react-slick';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './CampaignCombinations.module.css';

import Container from '../../../../blocks/Container';
import Title from '../../../../elements/Title';
import Image from '../../../../elements/Image';

import { capitalize } from '../../../../../utils';

const CaseStudies = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/campaign-combinations.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              subtitle
              combinations {
                name
                sliderClass
                class
                image
              }
            }
          }
        }
      }
    }
  `);

  const { title, subtitle, combinations } = data.allMarkdownRemark.edges[0].node.frontmatter;

  const sliderSettings = {
    className: 'brand-slider',
    centerMode: true,
    infinite: true,
    swipeToSlide: true,
    centerPadding: typeof window !== 'undefined' && window.innerWidth <= 576 ? (typeof window !== 'undefined' && window.innerWidth <= 400 ? '0' : '50px') : '20px',
    slidesToShow: typeof window !== 'undefined' && window.innerWidth > 768 ? 3 : (typeof window !== 'undefined' && window.innerWidth <= 576 ? 1 : 2),
    speed: 500,
    dots: false,
    initialSlide: 1,
    autoplay: true,
    arrows: false
  };

  return (
    <div className={styles.campaignCombinations}>
      <Container type="big">
        <Container type="mobile">
          <div className="row">
            <div className="col-12">
              <Title className={styles.title}>{title}</Title>
              <div className="w-100 d-flex justify-content-center">
                <div className={styles.textContainer} dangerouslySetInnerHTML={{ __html: subtitle }} />
              </div>
            </div>
          </div>
          <div className="row d-xl-flex d-lg-flex d-none">
            {
              combinations.map((combination, index) =>
                <div key={index} className="col-xl-3 col-lg-3 d-flex justify-content-center">
                  <div className={styles.typeContainer}>
                    <Image name={combination.image} className={styles[`typeContainer__image${capitalize(combination.class)}`]} />
                    <div className={styles.typeContainer__title}>{combination.name}</div>
                  </div>
                </div>
              )
            }
          </div>
          <div className="d-xl-none d-lg-none d-block">
            <Slider {...sliderSettings}>
              {
                combinations.map((combination, index) => (
                  <div key={index} className="col-12 d-flex justify-content-center align-items-start h-100">
                    <div key={index} className="col-xl-3 col-lg-3 d-flex justify-content-center">
                      <div className={styles.typeContainer}>
                        <Image name={combination.image} className={styles[`typeContainer__image${capitalize(process.env.NODE_ENV === 'development' ? combination.class : combination.sliderClass)}`]} />
                        <div className={styles.typeContainer__title}>{combination.name}</div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </Slider>
          </div>
        </Container>
      </Container>
    </div>
  )

}

export default CaseStudies;