import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './CampaignsHeader.module.css';

import Container from '../../../../blocks/Container';
import Title from '../../../../elements/Title';
import Image from '../../../../elements/Image';
import Text from '../../../../elements/Text';
import Breadcrumbs from '../../../../blocks/Breadcrumbs';

const CampaignsHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/campaigns-header.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              text
            }
          }
        }
      }
    }
  `);

  const { title, text } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.campaignsHeader}>
      <Container type="big">
        <Container type="mobile">
          <Breadcrumbs crumbs={['<a href="/features">Features</a>', 'Campaigns']} />
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12 d-flex flex-column align-items-xl-start align-items-lg-start align-items-center">
              <Title className={`${styles.title} text-left`}>{title}</Title>
              <Text className={styles.text}>{text}</Text>
            </div>
            <div className="col-xl-6 col-lg-6 col-12 d-flex flex-column align-items-xl-start align-items-lg-start align-items-center">
              <Image name="campaigns/woman_in_office" className={styles.image} />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default CampaignsHeader;
