import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './DifferentBlueprints.module.css';

import Container from '../../../../blocks/Container';
import Title from '../../../../elements/Title';
import Image from '../../../../elements/Image';
import BulletedList from '../../../../elements/BulletedList';

const DifferentBlueprints = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/different-blueprints.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              bullets
            }
          }
        }
      }
    }
  `);

  const { title, bullets } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.differentBlueprints}>
      <Container type="big">
        <Container type="mobile">
          <div className="row">
            <div className="col-12">
              <Title className={styles.title}>{title}</Title>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12 d-flex flex-column align-items-xl-start align-items-lg-start align-items-center">
              <div className={styles.bulletsContainer}>
                <BulletedList type="big" list={bullets} color="#00B7D1" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12 d-flex flex-column align-items-xl-start align-items-lg-start align-items-center">
              <Image name="campaigns/man_at_sofa" className={styles.image} />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  )

}

export default DifferentBlueprints;