import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './CampaignsAreFundamentalBlocks.module.css';

import Container from '../../../../blocks/Container';
import Title from '../../../../elements/Title';
import Image from '../../../../elements/Image';
import Text from '../../../../elements/Text';

const CampaignsAreFundamentalBlocks = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/campaigns-are-fundamental-blocks.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              text
            }
          }
        }
      }
    }
  `);

  const { title, text } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.slideContainer}>
      <Container type="big">
        <Container type="mobile">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12 d-flex flex-column align-items-xl-end align-items-lg-end align-items-center">
              <Title className={`${styles.title} text-left`}>{title}</Title>
              <Text className={`${styles.text} d-xl-block d-lg-block d-none`}>{text}</Text>
            </div>
            <div className="col-xl-6 col-lg-6 col-12 d-flex flex-column align-items-xl-end align-items-lg-end align-items-center">
              <Image name="campaigns/campaigns" className={styles.image} />
              <Text className={`${styles.text} d-xl-none d-lg-none d-block`}>{text}</Text>
            </div>
          </div>
        </Container>
      </Container>
    </div>
  )

}

export default CampaignsAreFundamentalBlocks;