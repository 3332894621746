import React from 'react';

import styles from './Breadcrumbs.module.css';

const Breadcrumbs = ({ crumbs }) => (
  <div className="row">
    <div className="col-xl-6 col-lg-6 col-12 d-flex flex-column align-items-start h-100">
      <div className={styles.breadcrumbs} dangerouslySetInnerHTML={{ __html: crumbs.join(' / ')}} />
    </div>
  </div>
);

export default Breadcrumbs;
