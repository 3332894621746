import React from 'react';

import Layout from '../../components/blocks/Layout';
import SEO from '../../components/blocks/SEO';
import CampaignsHeader from '../../components/slides/features/campaigns/CampaignsHeader';
import CaseStudies from '../../components/slides/features/campaigns/CaseStudies';
import CampaignsAreFundamentalBlocks from '../../components/slides/features/campaigns/CampaignsAreFundamentalBlocks';
import SevenComponents from '../../components/slides/features/campaigns/SevenComponents';
import CampaignCombinations from '../../components/slides/features/campaigns/CampaignCombinations/CampaignCombinations';
import DifferentBlueprints from '../../components/slides/features/campaigns/DifferentBlueprints';
import MixAndMatch from '../../components/slides/features/campaigns/MixMatch';
import ConnectMultiple from '../../components/slides/features/campaigns/ConnectMultiple';

const Campaigns = () => (
  <Layout>
    <SEO
      title="Campaigns | Deadline Funnel"
      description="Create a personal journey for each of your subscribers, and present unique offers to each person through your emails and landing pages."
    />
    <CampaignsHeader />
    <CaseStudies />
    <CampaignsAreFundamentalBlocks />
    <SevenComponents />
    <CampaignCombinations />
    <DifferentBlueprints />
    <MixAndMatch />
    <ConnectMultiple />
  </Layout>
);

export default Campaigns;