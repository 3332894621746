import React from 'react';
import Slider from 'react-slick';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './MixAndMatch.module.css';

import Container from '../../../../blocks/Container';
import Title from '../../../../elements/Title';
import Image from '../../../../elements/Image';
import Text from '../../../../elements/Text';

import { capitalize } from '../../../../../utils';

const MixAndMatch = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/mix-and-much.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              text
              templatesExamples {
                title
                text
                image
                class
                sliderClass
              }
            }
          }
        }
      }
    }
  `);

  const { title, text, templatesExamples } = data.allMarkdownRemark.edges[0].node.frontmatter;

  const sliderSettings = {
    className: 'brand-slider',
    centerMode: true,
    infinite: true,
    swipeToSlide: true,
    centerPadding: '20px',
    slidesToShow: typeof window !== 'undefined' && window.innerWidth <= 768 ? 1 : 2,
    speed: 500,
    dots: false,
    initialSlide: 1,
    autoplay: true,
    arrows: false
  };

  return (
    <div className={styles.mixAndMatch}>
      <Container type="big">
        <Container type="mobile">
          <div className="row">
            <div className="col-12">
              <Title className={styles.title}>{title}</Title>
              <Text className={styles.text}>{text}</Text>
            </div>
          </div>
          <div className="row d-xl-flex d-lg-flex d-none">
            {
              templatesExamples.map((blueprint, index) =>
                <div key={index} className="col-xl-4 col-lg-4 col-12 d-flex justify-content-center">
                  <div className={styles.blueprintBox}>
                    <Image name={blueprint.image} className={styles[`blueprintBox__image${capitalize(blueprint.class)}`]} />
                    <div className={styles.blueprintBox__spacer} />
                    <div className={styles.blueprintBox__title}>{blueprint.title}</div>
                    <div className={styles.blueprintBox__text}>{blueprint.text}</div>
                  </div>
                </div>
              )
            }
          </div>
          <div className="d-xl-none d-lg-none d-block">
            <Slider {...sliderSettings}>
              {
                templatesExamples.map((blueprint, index) => (
                  <div key={index} className="col-12 d-flex justify-content-center align-items-start h-100">
                    <div className={styles.blueprintBox}>
                      <Image name={blueprint.image} className={styles[`blueprintBox__image${capitalize(process.env.NODE_ENV === 'development' ? blueprint.class : blueprint.sliderClass)}`]} />
                      <div className={styles.blueprintBox__spacer} />
                      <div className={styles.blueprintBox__title}>{blueprint.title}</div>
                      <div className={styles.blueprintBox__text}>{blueprint.text}</div>
                    </div>
                  </div>
                ))
              }
            </Slider>
          </div>
        </Container>
      </Container>
    </div>
  )

}

export default MixAndMatch;